<template>
    <!-- Page: pages/register -->
    <div class="container">
        <div class="login-container">
            <h1 class="title">Create a new account</h1>
            <registration-form/>
            <br>
            <router-link to="/login">Already have an account? Sign In.</router-link>
        </div>
    </div>
    <!-- End Page: pages/register -->
</template>

<script>
    import Alert from "./alert"
    import RegistrationForm from "./registration-form";

    export default {
        components: {
            RegistrationForm,
            Alert,
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/_variables.scss";
    h1 {
        margin-bottom: 30px;
    }
    .login-container {
        max-width: 600px;
        margin: 0 auto;
        @media screen and (max-width: 650px) {
            margin: 0 15px;
        }
    }
    .title {
        margin-top: 70px;
    }
</style>
